import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import SEO from '../components/SEO';
import Loading from '../components/Loading';
import usePaymentCallback from '../hooks/usePaymentCallback';
import { messages as globalMessages } from '../components/messages';

interface CallbackPageProps {
  location: string;
}

function CallbackPage({ location }: CallbackPageProps) {
  usePaymentCallback(location);
  const { t } = useTranslation();
  return (
    <>
      <SEO
        keywords={[
          'paiement vignette maroc',
          'ma vignette',
          'payer en ligne vignette maroc',
          'paiement vignette maroc en ligne',
          'payer vignette en ligne',
          'vignette en ligne',
          'ma vignette maroc',
          'paiement en ligne maroc',
          'paiement internet maroc',
          'impot voiture maroc',
          'taxe voiture maroc',
          'payer taxe voiture maroc',
          'payer dariba maroc',
          'payer dariba en ligne',
          'payer dariba',
          'vignette 2022',
          'payer vignette 2022',
          'payer vignette 2022 maroc',
        ]}
      />
      <Loading text={t(globalMessages.custom.loadingText())} />
    </>
  );
}

export default memo(CallbackPage);

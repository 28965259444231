import { useCallback, useContext, useEffect } from 'react';
import * as queryString from 'querystring';
import { navigate } from 'gatsby';
import { GlobalContext } from '../context';
import { setPaymentResponse, setPaymentResultData } from '../context/actions';

// helper functions
const getCarStuff = (source: any, key: string) => {
  const paramsStr = source?.cf_params_global;
  const carParams = paramsStr ? paramsStr.split('|') : [];
  const result = carParams
    .map((e: any) => e.split(':'))
    .find((e: any) => e[0] === key);
  return result ? result[1] : '';
};

const getTaxStuff = (source: any, key: string) => {
  const paramsStr = source?.desc1;
  const taxParams = paramsStr ? paramsStr.split('<br/>') : [];
  const result = taxParams
    .map((e: any) => e.split(':'))
    .find((e: any) => e[0].trim() === key);
  return result ? result[1].trim() : '';
};

export default function usePaymentCallback(location: string) {
  const { dispatch } = useContext(GlobalContext);

  const handlePaymentCallback = useCallback(async () => {
    // @ts-ignore
    if (location.search !== '') {
      // @ts-ignore
      const params = queryString.parse(location.search.replace('?', ''));
      dispatch(setPaymentResponse(params));

      console.log('CALLBACK', { location, params });
      const { Response, DIMCRITERIA2 } = params;

      if (Response === 'Approved') {
        // get some payment data
        const dateTransaction =
          `${params?.['EXTRA.TRXDATE']}`.split(' ')[0] || '';
        const numRecu = params?.oid;
        const numTransaction = params?.acqStan;
        const numTransactionDGI = params?.DIMCRITERIA1;
        const fraisService = params?.total2;
        const TVAString: string = params?.desc2 as string;
        const TVA = TVAString ? TVAString?.split('TVA')?.[1] : '';
        const montantTotal = params?.amount;
        const clientDetection = params?.['EXTRA.CARDISSUER'];

        const anneePaiement = getCarStuff(params, 'periode_annee');
        const marqueVehicule = getCarStuff(params, 'marque');
        const matriculeVehicule = getCarStuff(params, 'matricule');
        const puissanceVehicule = getCarStuff(params, 'puissance_fiscale');
        const carburantVehicule = getCarStuff(params, 'carburant_type');
        const montantPrincipal = getTaxStuff(params, 'Montant principal');
        const penalites = getTaxStuff(params, 'Pénalités');
        const majorationRetard = getTaxStuff(params, 'Majoration de retard');

        // extra client data
        const adresseIpClient = params?.clientIp;
        const referenceTransaction = params?.oid;
        const idArticle = params?.id1;
        const codeAutorisationBanque = params?.AuthCode;
        const identifiantPaiementClient = params?.acqStan;
        const identifiantTransactionCmi = params?.TransId;
        const messageErreur = params?.ErrMsg;
        const typeCartePaiement = params?.['EXTRA.CARDBRAND'];
        const numeroCartePaiement = params?.MaskedPan;
        const nomPorteurCarte = params?.BillToName;
        const identifiantCmi = params?.merchantID;
        const identifiantAcquereur = params?.ACQBIN;
        const authentificationStatus = params?.mdStatus;

        dispatch(
          setPaymentResultData({
            data: {
              dateTransaction,
              numeroRecu: numRecu,
              numeroTransaction: numTransaction,
              numeroTransactionDGI: numTransactionDGI,
              anneePaiement,
              vehicule: marqueVehicule,
              immatriculation: matriculeVehicule,
              puissanceFiscale: puissanceVehicule,
              typeCarburant: carburantVehicule,
              montantPrincipal,
              penalites,
              penalitesDeRetard: majorationRetard,
              fraisDeService: fraisService,
              tva: TVA,
              totalPaiement: montantTotal,
              clientDetection,
            },
            extra: {
              adresseIpClient,
              referenceTransaction,
              idArticle,
              codeAutorisationBanque,
              identifiantPaiementClient,
              identifiantTransactionCmi,
              messageErreur,
              typeCartePaiement,
              numeroCartePaiement,
              nomPorteurCarte,
              identifiantCmi,
              identifiantAcquereur,
              authentificationStatus,
            },
          }),
        );

        if (DIMCRITERIA2 === '000' || DIMCRITERIA2 === '301') {
          // get success payment data

          // we redirect to success page
          await navigate('/success');
        } else {
          // get failed payment data

          // we redirect to fail page
          await navigate('/failed');
        }
      } else {
        await navigate('/');
      }

      // OLD LOGIC BASED ON OLD APP
      /*      if (ACQBIN) {
        // get some payment data
        const dateTransaction = params?.['EXTRA.TRXDATE']
          ? params?.['EXTRA.TRXDATE']?.slice(0, 3)
          : '';
        const numRecu = params?.oid;
        const numTransaction = params?.acqStan;
        const numTransactionDGI = params?.DIMCRITERIA1;
        const fraisService = params?.total2;
        const TVAString: string = params?.desc2 as string;
        const TVA = TVAString ? TVAString?.split('TVA')?.[1] : '';
        const montantTotal = params?.amount;
        const clientDetection = params?.['EXTRA.CARDISSUER'];

        const anneePaiement = getCarStuff(params, 'periode_annee');
        const marqueVehicule = getCarStuff(params, 'marque');
        const matriculeVehicule = getCarStuff(params, 'matricule');
        const puissanceVehicule = getCarStuff(params, 'puissance_fiscale');
        const carburantVehicule = getCarStuff(params, 'carburant_type');
        const montantPrincipal = getTaxStuff(params, 'Montant principal');
        const penalites = getTaxStuff(params, 'Pénalités');
        const majorationRetard = getTaxStuff(params, 'Majoration de retard');

        // extra client data
        const adresseIpClient = params?.clientIp;
        const referenceTransaction = params?.oid;
        const idArticle = params?.id1;
        const codeAutorisationBanque = params?.AuthCode;
        const identifiantPaiementClient = params?.acqStan;
        const identifiantTransactionCmi = params?.TransId;
        const messageErreur = params?.ErrMsg;
        const typeCartePaiement = params?.['EXTRA.CARDBRAND'];
        const numeroCartePaiement = params?.MaskedPan;
        const nomPorteurCarte = params?.BillToName;
        const identifiantCmi = params?.merchantID;
        const identifiantAcquereur = params?.ACQBIN;
        const authentificationStatus = params?.mdStatus;

        dispatch(
          setPaymentResultData({
            data: {
              dateTransaction,
              numeroRecu: numRecu,
              numeroTransaction: numTransaction,
              numeroTransactionDGI: numTransactionDGI,
              anneePaiement,
              vehicule: marqueVehicule,
              immatriculation: matriculeVehicule,
              puissanceFiscale: puissanceVehicule,
              typeCarburant: carburantVehicule,
              montantPrincipal,
              penalites,
              penalitesDeRetard: majorationRetard,
              fraisDeService: fraisService,
              tva: TVA,
              totalPaiement: montantTotal,
              clientDetection,
            },
            extra: {
              adresseIpClient,
              referenceTransaction,
              idArticle,
              codeAutorisationBanque,
              identifiantPaiementClient,
              identifiantTransactionCmi,
              messageErreur,
              typeCartePaiement,
              numeroCartePaiement,
              nomPorteurCarte,
              identifiantCmi,
              identifiantAcquereur,
              authentificationStatus,
            },
          }),
        );

        if (DIMCRITERIA2 === '000' || DIMCRITERIA2 === '301') {
          // get success payment data

          // we redirect to success page
          await navigate('/success');
        } else {
          // get failed payment data

          // we redirect to fail page
          await navigate('/failed');
        }
      } else {
        await navigate('/');
      } */
    } else {
      await navigate('/');
    }
  }, [location]);

  useEffect(() => {
    handlePaymentCallback();
  }, [handlePaymentCallback]);
}

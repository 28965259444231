import styled, { keyframes } from 'styled-components';

const scale = keyframes`
  0% {
    transform: scale(1)
  }
  50% {
    transform: scale(1.1)
  }
`;
const swing = keyframes`
  50% {
    transform: rotate(-3deg)
  }
`;
const steamLarge = keyframes`
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
`;
const steamSmall = keyframes`
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }
  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;

  svg {
    animation: ${scale} 0.75s infinite;
    &.tea {
      stroke: ${props => props.theme.colors.primary};
      #teabag {
        transform-origin: top center;
        transform: rotate(3deg);
        animation: ${swing} 0.75s infinite;
        stroke: ${props => props.theme.colors.primary};
      }

      #steamL {
        stroke-dasharray: 13;
        stroke-dashoffset: 13;
        animation: ${steamLarge} 0.75s infinite;
        stroke: ${props => props.theme.colors.primary};
      }

      #steamR {
        stroke-dasharray: 9;
        stroke-dashoffset: 9;
        animation: ${steamSmall} 0.75s infinite;
        stroke: ${props => props.theme.colors.primary};
      }
    }
  }
`;

export const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h6 {
    margin-top: 10px;
    font-family: ${props => props.theme.fonts.SourceSansPro};
    font-weight: ${props => props.theme.fontWeights.SourceSansPro.bold};
  }
`;

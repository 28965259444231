import { memo } from 'react';
import TeaCup from '../svgs/TeaCup';
import { LoaderContainer, LoaderWrapper } from './styles';

interface LoadingProps {
  text?: string;
}

function Loading({ text }: LoadingProps) {
  return (
    <LoaderContainer>
      <LoaderWrapper>
        <TeaCup />
      </LoaderWrapper>
      <h6>{text}</h6>
    </LoaderContainer>
  );
}

export default memo(Loading);
